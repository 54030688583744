const fs = require("fs");
const path = require("path");

// Since this script is in the same folder as the translation files:
const translationsDir = __dirname;

// Recursively sort an object’s keys
function sortObject(obj) {
	if (Array.isArray(obj)) {
		return obj.map(sortObject);
	} else if (obj && typeof obj === "object") {
		return Object.keys(obj)
			.sort((a, b) => a.localeCompare(b))
			.reduce((acc, key) => {
				acc[key] = sortObject(obj[key]);
				return acc;
			}, {});
	}
	return obj;
}

// Process one file
function processFile(filePath) {
	const content = fs.readFileSync(filePath, "utf8");
	const exportPrefix = "export default";

	// We only process files that start with "export default"
	if (!content.trim().startsWith(exportPrefix)) {
		console.warn(`Skipping file (no 'export default'): ${filePath}`);
		return;
	}

	// Remove the export prefix and trailing semicolon if present
	const objectString = content.slice(exportPrefix.length).trim().replace(/;$/, "");

	let data;
	try {
		// Safely evaluate the object string
		data = new Function("return " + objectString)();
	} catch (e) {
		console.error(`Error parsing file ${filePath}:`, e);
		return;
	}

	const sortedData = sortObject(data);
	// Re-stringify the sorted object with 2-space indentation
	const newContent = `${exportPrefix} ${JSON.stringify(sortedData, null, 2)};`;

	fs.writeFileSync(filePath, newContent, "utf8");
	console.log(`Sorted file: ${filePath}`);
}

// Read the directory and process each .js file except index.js
fs.readdirSync(translationsDir).forEach((file) => {
	if ((file.endsWith(".js") && file !== "index.js") || file !== "sortTranslations.js") {
		const filePath = path.join(translationsDir, file);
		processFile(filePath);
	}
});
